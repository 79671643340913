.body {
  /* MUI Typography Body */
  height: 100%;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  font-size: 0.875rem;
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.46429em;
}

h1 {
  /* MUI Typography Headline */
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.5rem;
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.35417em;
}

h2 {
  /* MUI Typography Title */
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.3125rem;
  font-weight: 500;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.16667em;
}

h3 {
  /* MUI Typography Subheading */
  margin-top: 10px;
  margin-bottom: 10px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1rem;
  font-weight: 400;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  line-height: 1.5em;
}

ul {
  margin-top: 0px;
  margin-bottom: 10px;
  padding-left: 10px;
  color: #000;
  list-style: disc;
}

/*ul.no-underline li a {
    text-decoration:none;
    transition: all 250ms;
}
ul.no-underline li:hover {
    text-decoration:underline;
    cursor: pointer;
}*/

.bottomstatusbar {
  position: absolute;
  left: 20%;
  right: 20%;
  bottom: 20px;
  z-index: 100;
  display: block;
  width: 385px;
  margin-right: auto;
  margin-left: auto;
  padding: 2px 5px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  background-color: #fff;
  text-align: center;
}

.bottomstatusbar.artifact_status_bar {
  display: none;
  min-width: 550px;
}

.bottomstatusbar.review_status_bar {
  display: none;
  min-width: 550px;
}

.bottomstatusbar.approve_status_bar {
  display: none;
  min-width: 550px;
}

.processbutton {
  display: block;
  width: 75px;
  margin-top: 0px;
}

.processbutton:hover {
  opacity: 0.95;
}

.actionbutton {
  height: 40px;
  margin-right: 7px;
  margin-left: 7px;
  float: left;
  cursor: pointer;
}

.actionbutton:hover {
  opacity: 0.9;
}

.actionbutton.titlebutton {
  cursor: default;
}

.bottomstatusleft {
  display: inline-block;
  width: 40%;
  float: left;
}

.bottomstatuscenter {
  display: block;
  width: 20%;
  margin-right: auto;
  margin-left: auto;
}

.bottomstatusright {
  display: inline-block;
  width: 40%;
  float: left;
  text-align: left;
}

.processdropdowntoggle {
  margin-top: -25px;
  padding: 0px;
}

.bubblelink {
  position: fixed;
  left: 37%;
  bottom: 95px;
  display: inline-block;
  width: 100px;
  padding: 10px 0px;
  border: 2px solid #fff;
  border-radius: 20px;
  background-color: #404041;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  color: #fff;
  text-align: center;
  text-decoration: none;
}

.bubblelink:hover {
  background-color: #131313;
}

.bubblelink.link2 {
  left: 46%;
  bottom: 140px;
}

.bubblelink.link3 {
  left: 55%;
}

.bubbleimage {
  position: absolute;
  left: 0px;
  top: -67px;
  right: 0px;
  display: block;
  height: 55px;
  margin-right: auto;
  margin-left: auto;
}

.doccontainer {
  position: absolute;
  left: 20%;
  top: 300px;
  display: block;
  padding: 15px;
  border-radius: 50%;
  background-color: #fff;
}

.doccontainer.reviewcontainer {
  left: 45%;
  top: 160px;
  display: none;
}

.doccontainer.approvecontainer {
  left: 73%;
  top: 300px;
  display: none;
}

.doccontainer.documentcontainer {
  display: block;
}

.outercontainer {
  position: absolute;
  left: -50px;
  top: -41px;
  width: auto;
  text-align: center;
}

.outercontainer.container2 {
  left: -76px;
  top: 37px;
  width: auto;
}

.outercontainer.outer_requester {
  left: 13px;
  top: -84px;
  width: auto;
}

.editcontainer {
  position: absolute;
  left: -5px;
  bottom: -5px;
}

.navbar {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  z-index: 9999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 55px;
  padding: 5px 10px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #004884;
  color: #fff;
}

.header_dropdown {
  color: #fff;
}

.header_dropdown_toggle {
  padding: 5px 40px 5px 2px;
  color: #fff;
  font-weight: 300;
}

.header_dropdown_h1 {
  margin-top: 3px;
  margin-bottom: 0px;
  font-size: 16px;
  line-height: 16px;
  font-weight: 500;
}

.dropdown_user_text {
  line-height: 14px;
}

.navbar__left {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 225px;
  padding-top: 0px;
  float: left;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  border-style: none;
  border-right-width: 1px;
  border-right-color: #fff;
}

.header_dropdown_list {
  padding: 5px;
}

.header_dropdown_list.w--open {
  min-width: 150px;
  margin-top: 15px;
  padding: 0px;
  border-radius: 5px;
  background-color: #fff;
  opacity: 1;
}

.header_dropdown_link {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-right: 15px;
  padding-left: 15px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 3px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.header_dropdown_link:hover {
  background-color: #f5f5f5;
}

.header_dropdown_link.w--current {
  background-image: url('../images/BlueBall.png');
  background-position: 6px 50%;
  background-size: 10px;
  background-repeat: no-repeat;
  color: #495da5;
}

.navbar__center {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  padding-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.navbar__right {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: auto;
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.navbar__icon_link {
  position: relative;
  margin-right: 10px;
  margin-left: 10px;
  opacity: 1;
  text-decoration: none;
  cursor: pointer;
}

.navbar__icon_link.main_icon {
  margin-right: 5px;
  margin-left: 5px;
  padding: 7px;
  opacity: 0.8;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.navbar__icon_link.main_icon:hover {
  opacity: 1;
}

.navbar__icon_link.toggle_link {
  opacity: 1;
  -webkit-transition-property: none;
  transition-property: none;
}

.navbar__icon_link.hide {
  display: none;
}

.navbar_icon_image {
  height: 20px;
  margin-right: 10px;
  margin-left: 10px;
  opacity: 0.8;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.navbar_icon_image:hover {
  opacity: 1;
}

.navbar_icon_image.main_image {
  height: 35px;
  opacity: 1;
}

.notification_badge {
  position: absolute;
  right: -5px;
  bottom: -5px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 2px none #242424;
  border-radius: 50%;
  background-color: #ef3938;
  opacity: 1;
  color: #fff;
  font-size: 11px;
  font-weight: 200;
}

.navbar_center_main {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  float: right;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
}

.navbar_dropdown_toggle {
  padding: 5px;
  opacity: 0.8;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.navbar_dropdown_toggle:hover {
  opacity: 1;
}

.navbar_dropdown_toggle.w--open {
  opacity: 1;
}

.form-block {
  position: relative;
  margin-bottom: 0px;
  margin-left: -10px;
}

.toggle_wrap {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 32px;
  margin-bottom: 0px;
  padding: 7px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: grey;
  color: #fff;
  font-size: 16px;
  text-decoration: none;
}

.toggle_ticker {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  margin-left: 0px;
  float: none;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 10px;
  background-color: #dae2f8;
  opacity: 0;
  color: #000;
  cursor: pointer;
}

.toggle_link.navbar__icon_link {
  overflow: hidden;
}

.toggle_link.navbar__icon_link.hide {
  display: none;
}

.workspace {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  height: 100%;
  /* padding-top: 55px; */
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #fff;
}

.workspace.flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.workspace.login-body {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-image: url('../images/loginbackground.jpeg');
  background-position: 50% 50%;
  background-size: cover;
}

.dropdown_icon {
  margin-right: 10px;
  font-family: 'Fontawesome webfont';
}

.dropdown_menu_head {
  padding: 5px;
  border-bottom: 1px solid #c3c3c3;
  color: #000;
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.dropdown_menu_head.dropdown_footer {
  display: block;
  border-bottom-style: none;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  text-decoration: none;
  text-transform: none;
}

.dropdown_menu_head.dropdown_footer:hover {
  text-decoration: underline;
}

.panel {
  position: absolute;
  left: 20px;
  top: 15%;
  display: block;
  min-width: 220px;
  border: 1px solid #fff;
}

.panel.panel_documents {
  left: 98px;
  top: 31%;
  z-index: 2;
  display: none;
}

.panel.panel_documents.draggable {
  display: none;
}

.panel.panel_projects {
  left: 58px;
  top: 22%;
  z-index: 2;
  display: none;
}

.panel.panel_projects.draggable {
  display: block;
}

.panel.panel_people {
  z-index: 1;
}

.panel.panel_people.draggable {
  display: none;
}

.panel.panel_new_document {
  left: 34%;
  top: 18%;
  display: none;
  margin-right: auto;
  margin-left: auto;
}

.panel.panel_new_document.draggable {
  left: 20%;
  top: 18%;
  right: 20%;
  display: none;
}

.panel.panel_status.draggable {
  left: 120px;
  display: none;
}

.panel_head {
  padding: 15px 10px 15px 15px;
  background-color: #3ec4e5;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.panel_body {
  overflow: visible;
  padding-top: 5px;
  padding-left: 8px;
  background-color: transparent;
}

.panel_body.doc_review_body {
  padding: 15px;
}

.panel_buttons {
  float: right;
}

.panel_header_text {
  display: inline-block;
  color: #fff;
  font-weight: 500;
}

.panel_button_link {
  margin-right: 5px;
  margin-left: 3px;
  opacity: 0.8;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  font-family: 'Fontawesome webfont';
  color: #fff;
  font-size: 19px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
}

.panel_button_link:hover {
  opacity: 1;
}

.panel_item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 5px 15px 5px 5px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 15px;
  line-height: 15px;
  font-weight: 300;
  cursor: pointer;
}

.panel_item:hover {
  background-color: #494949;
}

.panel_doc_review_link {
  margin-top: 15px;
  margin-right: 15px;
  margin-left: 15px;
}

.document_source_logo {
  height: 110px;
}

.nav_bottom_border {
  position: absolute;
  left: 0px;
  right: -2px;
  bottom: 0px;
  width: 100%;
  height: 2px;
  background-image: -webkit-linear-gradient(0deg, #d0d1d3, #a8a9ab 30%, #59595b 61%, #363636);
  background-image: linear-gradient(90deg, #d0d1d3, #a8a9ab 30%, #59595b 61%, #363636);
}

/* .panel__search_container {
  padding-top: 5px;
  padding-bottom: 5px;
} */

.panel__search_field {
  display: block;
  width: 100%;
  height: 35px;
  margin-right: auto;
  margin-bottom: 0px;
  margin-left: auto;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 40px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  font-family: Arial, Helvetica, sans-serif;
}

.panel__search_field:hover {
  background-color: #f5f5f5;
}

.form {
  position: relative;
}

.form.search_form {
  position: relative;
}

.navbar__close {
  display: none;
}

.navbar__search_container {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 3;
  overflow: hidden;
  width: 0px;
  margin-top: -8px;
  margin-right: -8px;
  -webkit-transition: opacity 650ms ease, width 450ms ease;
  transition: opacity 650ms ease, width 450ms ease;
}

.navbar__search_field {
  display: block;
  width: 100%;
  margin-bottom: 0px;
  padding-left: 40px;
  float: right;
  border-radius: 20px;
  background-color: #fff;
  background-image: url('../images/search.png');
  background-position: 97% 50%;
  background-size: 20px;
  background-repeat: no-repeat;
  font-size: 16px;
}

.navbar__search_close {
  position: absolute;
  left: -2px;
  top: 9px;
  z-index: 20;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-right: 0px;
  margin-left: 10px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
  cursor: pointer;
}

.navbar__search_close:hover {
  opacity: 0.5;
}

.navbar__mobile_menu_text {
  display: none;
}

.message {
  position: absolute;
  left: 18%;
  top: 23%;
  right: 18%;
  z-index: 3;
  display: block;
  max-width: 300px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 5px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-image: -webkit-linear-gradient(0deg, #4775d4, #00f5d7);
  background-image: linear-gradient(90deg, #4775d4, #00f5d7);
}

.message.message_author {
  left: auto;
  top: -100px;
  right: -36px;
  display: none;
  width: 215px;
  height: 68px;
}

.message.message1 {
  display: block;
}

.message.message2 {
  left: 18%;
  top: auto;
  right: 18%;
  bottom: 20%;
  display: none;
}

.message.message3 {
  left: 13%;
  top: auto;
  right: auto;
  bottom: 16%;
  display: none;
}

.message.message4 {
  left: -65px;
  top: -100px;
  right: auto;
  bottom: auto;
  display: none;
}

.message.message5 {
  left: 18%;
  top: auto;
  right: 18%;
  bottom: -64px;
  display: none;
  max-width: 250px;
}

.message.message6 {
  left: auto;
  top: 23%;
  right: 18%;
  display: none;
}

.message.message7 {
  left: -80px;
  top: -106px;
  right: -80px;
  display: none;
}

.message.message8 {
  left: auto;
  top: 42%;
  right: -260px;
  display: none;
}

.message.message9 {
  left: -80px;
  top: -103px;
  right: -80px;
  display: none;
}

.message.message10 {
  left: auto;
  top: 48%;
  right: -289px;
  display: none;
}

.message.message11 {
  left: 18%;
  top: 42%;
  right: 18%;
  bottom: auto;
  display: none;
}

.message.message13 {
  left: auto;
  top: 23%;
  right: 3%;
  display: none;
}

.message.message14 {
  left: auto;
  top: 48%;
  right: -289px;
  display: none;
}

.message.message15 {
  left: auto;
  top: auto;
  right: 11%;
  bottom: 14%;
  display: none;
}

.message.message16 {
  left: auto;
  top: 12%;
  right: 3%;
  display: none;
}

.message.message17 {
  left: auto;
  top: 12%;
  right: 3%;
  display: none;
}

.message_interior {
  position: static;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  padding: 2px 12px;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #fff;
  text-align: center;
}

.message__paragraph {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #000;
}

.message__button {
  margin-top: 12px;
  margin-bottom: 15px;
  border-radius: 3px;
  background-color: #1e5dd1;
  -webkit-transition: opacity 200ms ease;
  transition: opacity 200ms ease;
}

.message__button:hover {
  opacity: 0.9;
}

.message__arrow {
  position: absolute;
  left: 40px;
  right: 40px;
  bottom: -15px;
  display: block;
  width: 0px;
  height: 0px;
  margin-right: auto;
  margin-left: auto;
  border-top: 20px solid #fff;
  border-right: 20px solid transparent;
  border-left: 20px solid transparent;
}

.message__arrow.right {
  left: auto;
  top: auto;
  right: -15px;
  bottom: 5px;
  border-bottom: 20px solid transparent;
  border-top-color: transparent;
  border-right-style: none;
  border-left-color: #fff;
}

.message__arrow.message_arrow_top {
  left: 40px;
  top: -20px;
  right: 40px;
  bottom: auto;
  border-bottom: 20px solid #fff;
  border-top-style: none;
}

.message__arrow.left {
  left: -16px;
  top: 2px;
  right: auto;
  bottom: -20px;
  border-bottom: 20px solid transparent;
  border-top-color: transparent;
  border-right-color: #fff;
  border-left-style: none;
}

.message__quote {
  position: absolute;
  left: -40px;
  top: 0px;
  right: 0px;
  width: 30px;
}

.message__quote.right_side {
  left: auto;
  top: 0px;
  right: -40px;
}

.panel__whitebackground {
  margin: 20px -15px;
  padding-bottom: 15px;
  background-color: #fff;
  text-align: center;
}

.outercontainer__text {
  position: absolute;
  left: 0px;
  top: -20px;
  right: 0px;
  font-size: 12px;
  font-weight: 200;
  text-align: center;
}

.sidecontainer {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: -1;
  display: none;
  width: 35px;
  padding: 5px;
  border-radius: 100%;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  cursor: pointer;
}

.sidecontainer:hover {
  z-index: 0;
}

.sidecontainer.dollar_container {
  top: 0px;
  right: -15px;
  display: block;
  padding: 5px;
  background-color: red;
}

.sidecontainer.keyboard_container {
  top: 37px;
  right: -25px;
  display: block;
  background-color: green;
}

.sidecontainer.user_container {
  top: 73px;
  right: -17px;
  display: block;
  background-color: #ece57b;
}

.sidecontainer.remove_container {
  top: 101px;
  right: 12px;
  bottom: 16px;
  z-index: -1;
  display: block;
  width: 25px;
  height: 25px;
  padding: 2px;
  background-color: #e4e4e4;
}

.outer__icon {
  width: 50px;
  border: 1px solid #fff;
  border-radius: 100%;
  cursor: pointer;
}

.panel__panel_icon {
  width: 30px;
  height: 30px;
  margin-right: 7px;
  float: left;
  border: 1px none #fff;
  border-radius: 100%;
}

.panel_text {
  display: inline-block;
  float: left;
}

.doccontainer__image {
  width: 86px;
  height: 86px;
}

.navbar_logo {
  width: 214px;
  padding-right: 10px;
  border-style: none;
  border-right-width: 1px;
  border-right-color: #fff;
}

.logincontainer {
  padding: 30px;
  border: 1px solid dimgrey;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 1px 1px 3px 0 rgba(0, 0, 0, 0.31);
}

.drawer {
  width: auto;
  height: auto;
  border-style: none;
  border-right-width: 1px;
  border-right-color: #fff;
  background-color: #333541;
}

.filter-dropdown {
  display: none;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #fff;
  font-weight: 200;
}

.drawer-tab-link {
  padding-right: 8px;
  padding-left: 8px;
  background-color: transparent;
  color: #fff;
  font-size: 12px;
  font-weight: 200;
}

.drawer-tab-link.w--current {
  background-color: #424242;
  font-weight: 500;
}

.drawer-list {
  overflow: visible;
  color: #fff;
  text-align: left;
}

.list-item-icon {
  width: 25px;
  margin-right: 5px;
  float: left;
}

.list-header {
  padding-bottom: 0px;
  margin-bottom: 5px;
  /* margin-right: 15px; */
  font-weight: 600;
  color: white;
  border-bottom: 1px solid white;
  font-family: Arial, Helvetica, sans-serif;
}

.winflow-links {
  /* margin-left: 4px; */
  padding-bottom: 5px;
  text-align: left;
  width: 100%;
  /* padding-right: 25px; */
}

.winflow-links-bold {
  /* margin-left: 4px; */
  padding-bottom: 5px;
  text-align: left;
  width: 100%;
  font-weight: bold;
  color: #3fc4e5;
}

.winflow-links:hover {
  cursor: pointer;
  font-weight: bold;
  color: #98dff0;
}

.drawer-list-item {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 5px 10px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 200;
}

.count {
  font-weight: 500;
}

.left-tabs {
  position: relative;
  left: 0px;
  top: 0px;
  bottom: 0px;
  z-index: 2;
  display: block;
  width: 225px;
  height: 100%;
  margin-top: 0px;
  padding-top: 0px;
  border-right: 1px solid #fff;
  background-color: #d1d1d1;
}

.left-tabs-menu {
  position: static;
  left: 0px;
  top: 0px;
  right: 0px;
  width: auto;
  padding: 5px;
  background-color: #dadada;
}

.left-tabs-content {
  background-color: #d1d1d1;
}

.left-tabs-link {
  display: block;
  padding: 5px;
  border: 1px none #fff;
  border-radius: 0px;
  background-color: transparent;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  text-align: left;
}

.left-tabs-link:hover {
  color: #4b4b4b;
}

.left-tabs-link.w--current {
  position: relative;
  left: 0px;
  z-index: 10;
  display: block;
  padding-left: 5px;
  border-left-style: none;
  background-color: transparent;
  font-weight: 700;
  text-align: left;
}

.left-tabs-pane {
  overflow: visible;
}

.tabs-image {
  width: 25px;
}

.microflow {
  position: static;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  min-height: 110px;
  padding: 10px 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #b3b3b3;
  border-radius: 8px;
  background-color: #ededef;
  box-shadow: inset 0 0 8px 0 #000;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  cursor: pointer;
}

.microflow:hover {
  box-shadow: 0 0 6px 0 #52c5c2;
}

.microflow.active {
  position: relative;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border: 1px solid #b3b3b3;
  background-color: #ededef;
  box-shadow: inset 0 0 8px 0 #000;
}

.microflow.active:hover {
  box-shadow: none;
}

.microflow-container {
  position: static;
  left: 27%;
  top: 200px;
  width: 145px;
  margin-right: 15px;
  padding: 3px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
}

.microflow-container.container3 {
  left: 82%;
}

.microflow-container.active {
  width: 360px;
}

.microflow-container.skewed {
  opacity: 0.5;
  -webkit-transform: skew(0deg, -4deg);
  -ms-transform: skew(0deg, -4deg);
  transform: skew(0deg, -4deg);
}

.smallblue {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 15px;
  height: 15px;
  margin: 0px auto;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  border-radius: 100%;
  background-color: #0e729e;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.25);
}

.smallblue.active {
  width: 50px;
  height: 50px;
  margin-top: 0px;
  margin-bottom: 3px;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.25);
}

.smallblue.active.smallwhite {
  position: relative;
}

.smallblue.active.picked {
  border: 1px solid #f7bb3a;
}

.bigblue {
  display: block;
  width: 40px;
  height: 40px;
  margin-right: auto;
  margin-left: auto;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  border: 1px solid #fff;
  border-radius: 8px;
  background-color: #f7f7f7;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.25);
}

.bigblue.active {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 70px;
  height: 70px;
  margin-top: 0px;
  margin-bottom: 0px;
  padding: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-color: #000;
  background-color: #fff;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.25);
}

.bigblue.active.sidebar {
  width: 80px;
  height: 80px;
  font-size: 14px;
}

.grid50 {
  width: 50%;
  padding: 0px;
  -webkit-box-flex: 0;
  -webkit-flex: 0 auto;
  -ms-flex: 0 auto;
  flex: 0 auto;
  text-align: center;
}

.grid50.left {
  text-align: left;
}

.grid100 {
  width: 100%;
  padding: 5px 0px;
}

.smallwhite {
  display: block;
  width: 15px;
  height: 15px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 100%;
  background-color: #fff;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.25);
}

.smallwhite.active {
  width: 50px;
  height: 50px;
  margin-top: 0px;
  margin-bottom: 3px;
}

.smallwhite.active.blueborder {
  width: 60px;
  height: 60px;
  border: 4px solid #0e729e;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.25);
}

.microflow-h3 {
  display: inline-block;
  margin: 0px auto 5px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #333541;
  font-size: 17px;
  line-height: 22px;
  font-weight: bold;
  text-align: center;
  cursor: default;
  font-family: Arial, Helvetica, sans-serif;
}

.microflow-h3.new-microflow-h3 {
  cursor: default;
}

.microflow-h3.days-left {
  font-size: 16px;
}

.workspace-canvas {
  position: absolute;
  left: 0px;
  top: -55px;
  right: 0px;
  bottom: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  height: 100%;
  padding: 55px 15px 5px 260px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  -webkit-align-content: flex-end;
  -ms-flex-line-pack: end;
  align-content: flex-end;
  background-color: transparent;
}

.notifications-canvas {
  top: 55px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: flex-start;
}

.mission-control {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin-left: 240px;
}

.edit-button {
  position: static;
  top: 96px;
  right: 0px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 10px 5px 10px 10px;
  border-style: solid none solid solid;
  border-width: 1px;
  border-color: #fff;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #fff;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  background-color: #3ec4e5;
  color: #ffffff;
  cursor: pointer;
}
.edit-button-text {
  padding-top: 3px;
  font-weight: 500;
}

/*.edit-button:hover {
  padding-right: 5px;
  background-color: #f5f5f5;
  color:rgb(0, 72, 132);
}*/

.edit-button.info-button {
  top: 146px;
  margin-bottom: 1px;
}

.edit-button.info-button:hover {
  background-color: #fff;
}

.edit-button.upload-button {
  top: 197px;
}

.edit-image {
  width: 25px;
  height: 25px;
}

.edit-image:hover {
  background-color: #f5f5f5;
}

.timeline-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: hidden;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.timeline-container._3d-timeline {
  position: absolute;
  left: 104px;
  bottom: 9px;
  width: 170px;
  padding: 3px;
  border-radius: 6px;
  background-color: #000;
  -webkit-transform: rotate(-12.5deg);
  -ms-transform: rotate(-12.5deg);
  transform: rotate(-12.5deg);
}

.timeline-block {
  position: relative;
  width: 20%;
  height: 26px;
  margin-left: 17px;
  float: none;
  background-color: #fff;
}

.timeline-block.first-block {
  margin-left: 0px;
  background-color: red;
}

.timeline-block.first-block._3d {
  height: 14px;
  margin-left: 0px;
}

.timeline-block._3d {
  height: 14px;
  margin-left: 10px;
}

.front-triangle {
  position: absolute;
  top: 0%;
  right: -14px;
  bottom: 0px;
  z-index: 2;
  width: 0px;
  height: 0px;
  margin-top: 0px;
  border-top: 14px solid transparent;
  border-bottom: 14px solid transparent;
  border-left: 14px solid red;
}

.front-triangle._3d {
  right: -7px;
  border-top-width: 7px;
  border-bottom-width: 7px;
  border-left-width: 8px;
}

.back-triangle {
  position: absolute;
  left: -13px;
  top: 0px;
  bottom: 0px;
  width: 0px;
  height: 0px;
  border-top: 14px solid #fff;
  border-bottom: 14px solid #fff;
  border-left: 13px solid transparent;
}

.back-triangle._3d {
  left: -6px;
  border-top-width: 7px;
  border-bottom-width: 7px;
  border-left-width: 7px;
}

.title-text {
  font-weight: 200;
}

.workitem-text {
  color: #fff;
  font-size: 12px;
  font-weight: 300;
}

.artifact-text {
  color: #fff;
  font-size: 13px;
  line-height: 15px;
  font-weight: 300;
  text-align: center;
  margin-top: 10px;
}

.artifact-text.sidepanel {
  font-size: 11px;
  line-height: 13px;
}

.name-text {
  margin-top: 5px;
  line-height: 15px;
}

.sidedrawer {
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: none;
  width: 225px;
  height: 100%;
  padding-top: 40px;
  border-left: 1px solid #fff;
  background-color: #4f4f4f;
}

.sidedrawer_instructions {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  font-size: 11px;
  line-height: 13px;
  color: #000000;
  text-align: left;
}
.submenu-container {
  color: #fff;
}

.submenu-head {
  padding: 10px 20px;
  background-color: #fff;
  color: #333541;
  font-weight: 600;
}

.add-item-input {
  width: 145px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  color: #333541;
  font-weight: 600;
}

.submenu-work-item {
  padding: 5px 5px;
  color: #000;
  font-weight: 200;
}

.submenu-body {
  padding: 10px 10px;
  background-color: #fff;
}

.submenu-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.submen-text-block {
  font-weight: 200;
}

.edit-link {
  display: block;
  margin-top: 5px;
  color: #fff;
}

.panel_image {
  width: 20px;
  height: 20px;
}

.navbar-search-container {
  max-width: 200px;
}

.horizontal-scroll-container {
  display: block;
  width: 90%;
  height: 25px;
  margin-top: 25px;
  margin-right: auto;
  margin-left: auto;
  background-color: #58595b;
}

.microflow-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.scroll-end {
  float: left;
  background-color: #4f4f4f;
}

.scroll-end.right-end {
  float: right;
}

.scroll-arrow {
  height: 25px;
}

.scroll-bar {
  width: 100px;
  height: 25px;
  margin-left: 64px;
  background-color: #353535;
}

.scroll-wrapper {
  display: none;
}

.search-image {
  position: absolute;
  left: 10px;
  top: 0px;
  bottom: 0px;
  display: block;
  width: 30px;
  height: 30px;
  margin-top: 3px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
}

.search-image.clear {
  display: block;
  cursor: pointer;
}

.avatar {
  margin-left: 10px;
}

.avatar-image {
  width: 35px;
  height: 35px;
  border: 2px solid #dbdbdb;
  border-radius: 100%;
}

.count-container {
  margin-left: 5px;
  padding: 3px;
  border-radius: 100%;
}

.count-container.green {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  margin-left: 7px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #51af5a;
}

.logo {
  width: auto;
  max-width: 230px;
}

.login-container {
  display: block;
  text-align: center;
}

.loginh3 {
  color: #000;
  font-weight: 400;
  text-align: center;
}

.label {
  font-weight: 300;
  text-align: left;
}

.user-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 20px;
  padding: 4px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #949494;
  border-radius: 8px;
}

.avatar-login {
  width: 50px;
  height: 50px;
  border-radius: 100%;
}

.login-text {
  margin-right: 20px;
  margin-left: 20px;
}

.login-text.title-text {
  max-width: 200px;
}

.login-text.name-text {
  width: 50px;
}

.intro-text {
  max-width: 620px;
  margin-bottom: 20px;
  float: none;
  text-align: center;
}

.login-top-container {
  text-align: center;
}

.login-logo-image {
  display: block;
  margin-right: auto;
  margin-left: auto;
}

._3d-wrapper {
  position: relative;
  width: 500px;
}

.z-plane {
  position: static;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
}

.microflow-text {
  position: absolute;
  left: 110px;
  bottom: 23px;
  -webkit-transform: rotate(-13deg);
  -ms-transform: rotate(-13deg);
  transform: rotate(-13deg);
}

.microflow-title {
  position: absolute;
  right: 31px;
  bottom: 78px;
  -webkit-transform: rotate(-12deg);
  -ms-transform: rotate(-12deg);
  transform: rotate(-12deg);
}

.work-artifact {
  position: absolute;
  top: 0px;
  right: 44%;
  z-index: 50;
}

.work-artifact-image {
  width: 125px;
}

.person-container {
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 2;
}

.person-container.person1 {
  top: -88px;
  z-index: 2;
}

.person-container.person2 {
  left: auto;
  top: auto;
  right: 120px;
  bottom: 113px;
  z-index: 2;
}

.person-container.person3 {
  left: auto;
  top: auto;
  right: 187px;
  bottom: 151px;
}

.person-container.person4 {
  left: auto;
  top: auto;
  right: 0px;
  bottom: 115px;
}

.person-container.person5 {
  left: auto;
  top: auto;
  right: 70px;
  bottom: 157px;
}

.person-image {
  position: relative;
  z-index: 1;
  height: 150px;
  -webkit-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

.arrow-image {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -20px;
  z-index: 0;
  width: 104%;
}

.edit-microflow-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: stretch;
  -webkit-align-items: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
}

.edit-microflow {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  width: 100%;
  max-width: none;
  padding: 5px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 6px;
  background-color: #eef2e5;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
  color: #000;
  font-size: 11px;
  line-height: 14px;
  font-weight: 300;
  text-align: center;
  border-radius: 6px;
  border: 1px solid #e1e8d0;
}

.edit-microflow:hover {
  background-color: #e1e8d0;
}

.edit-microflow.more-height {
  position: relative;
  min-height: 50px;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
}

.grid33 {
  position: relative;
  width: 33.33333333%;
  padding: 3px;
}

.motionbox-grid33 {
  position: relative;
  width: 33.33333333%;
  padding: 3px;
  height: 45px;
}

.motionbox-grid33:hover {
}

.workItem-grid33 {
  position: relative;
  width: 33.33333333%;
  padding: 3px;
  height: 50px;
}

.edit-people-item {
  margin-top: 5px;
  margin-bottom: 5px;
  padding: 0px 0px 0px 5px;
}

.edit-people-image {
  width: 25px;
  margin-right: 10px;
  float: left;
}

.edit-people-text {
  float: left;
  font-weight: 300;
}

.edit-person-container {
  cursor: pointer;
  margintop: 5px;
  marginbottom: 5px;
  padding: 0px 0px 0px 5px;
  color: black;
  display: flex;
  flexdirection: row;
}

.edit-person-container:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.edit-work-items:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.edit-document {
  padding: 2px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.reuse-option {
  cursor: pointer;
  text-align: left;
  opacity: 0.8;
  font-size: 14px;
  color: #333541;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 4px;
}

.reuse-option:hover {
  color: #3ec4e5;
}

.microflow-bottom-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 10px;
  padding-top: 0px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  text-align: left;
}

.start-date-container {
  position: relative;
  display: inline-block;
  margin-right: 0px;
  padding: 0px 7px;
  float: left;
  border-radius: 8px;
  background-color: #b6b6b6;
  font-size: 11px;
  line-height: 27px;
  font-weight: 300;
}

.start-date-container.end-date-container {
  width: 60px;
  margin-right: 0px;
  float: right;
  border-radius: 8px;
  background-color: #b6b6b6;
  font-size: 11px;
  font-weight: 300;
  text-align: center;
}

.start-date-container.end-date-container.initial-date {
  display: block;
  padding-right: 0px;
  padding-left: 0px;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
  background-color: transparent;
}

.start-date-container.initial-date {
  padding-right: 0px;
  padding-left: 0px;
  background-color: transparent;
}

.end-date-container {
  display: inline-block;
  background-color: #494949;
}

.close-container {
  position: absolute;
  color: #333541;
  top: 0px;
  right: 0px;
  width: 15px;
  height: 15px;
  margin-right: 3px;
  opacity: 0.4;
  -webkit-transition: opacity 250ms ease;
  transition: opacity 250ms ease;
  cursor: pointer;
}

.close-container:hover {
  opacity: 1;
}

.document {
  display: block;
  width: 30px;
  height: 30px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 8px;
  background-color: #4ea0a9;
}

.document.active {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75px;
  height: 75px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 8px;
  background-color: #4ea0a9;
  color: #fff;
}

.document.new-document {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100px;
  height: 100px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.document.comments-box {
  left: 0%;
  top: -25%;
}

.new_button {
  display: block;
  width: 160px;
  margin-top: 5px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 8px;
  background-color: #353535;
  -webkit-transition: background-color 200ms ease;
  transition: background-color 200ms ease;
  text-align: center;
}

.new_button:hover {
  background-color: #292929;
}

.panel_section {
  /*padding-top: 5px;
  padding-bottom: 5px;*/
  padding: 0px 10px;
  overflow-y: auto;
  height: 70%;
}

.panel_section_h3 {
  margin-top: 10px;
  margin-bottom: 0px;
  color: #000;
  font-size: 14px;
  font-weight: 200;
}

.new-process-wrapper {
  position: relative;
  display: none;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.document-connect-wrapper {
  position: relative;
  margin-bottom: 40px;
  padding: 15px 30px;
  border-radius: 6px;
  background-color: #4f4f4f;
}

.storage-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.dropbox-logo {
  width: 100px;
  height: 100px;
  margin-top: 10px;
  cursor: pointer;
}

.bottom-arrow {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: -30px;
  display: block;
  width: 30px;
  margin-right: auto;
  margin-left: auto;
  border-top: 30px solid #4f4f4f;
  border-right: 30px solid transparent;
  border-left: 30px solid transparent;
}

.document-data-wrapper {
  position: absolute;
  top: 0px;
  right: -186%;
  bottom: 0px;
  display: none;
  min-width: 150px;
  padding: 5px 10px;
  border: 1px solid #fff;
  border-radius: 8px;
  background-color: #8d8d8d;
}

.doc-meta-text {
  font-weight: 200;
}

.new-process-canvas {
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  display: none;
  width: 100%;
  height: 100%;
  padding-left: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.new-microflow-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 80%;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.new-microflow-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.grid20 {
  width: 20%;
  padding: 6px;
}

.grid20:hover {
}

.new-microflow-h4 {
  margin-top: 0px;
  color: #333;
  font-weight: 400;
}

.microflow-setup-wrapper {
  display: none;
}

.process-tooltip {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 75px;
  z-index: 3;
  display: block;
  width: 200px;
  padding: 4px;
  border: 1px solid #fff;
  border-radius: 6px;
  background-color: #8d8d8d;
  font-size: 13px;
  line-height: 16px;
}

.details-text {
  margin-bottom: 0px;
  line-height: 22px;
  font-weight: 600;
}

.arrow-down {
  position: absolute;
  left: 0px;
  right: 75px;
  bottom: -19px;
  display: block;
  width: 20px;
  height: 20px;
  margin-right: auto;
  margin-left: auto;
  border-style: solid solid none;
  border-width: 20px;
  border-color: #8d8d8d transparent transparent;
  background-color: transparent;
}

.initiate-button {
  border-radius: 8px;
  background-color: #4f4f4f;
}

.message-tip {
  position: absolute;
  top: 10px;
  right: -155px;
  bottom: 10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 135px;
  height: 40px;
  padding: 10px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #fff;
  border-radius: 8px;
  background-color: #3f93ed;
  box-shadow: 1px 1px 19px 0 rgba(0, 0, 0, 0.3);
  text-align: center;
}

.message-tip.left {
  left: -155px;
  top: 10px;
  right: auto;
  bottom: 0px;
}

.message-tip.bottom {
  left: -53%;
  top: auto;
  right: 0%;
  bottom: -50px;
  display: block;
  width: 110px;
  margin-right: auto;
  margin-left: auto;
  padding: 7px;
  font-size: 12px;
}

.arrow-left {
  position: absolute;
  left: -9px;
  top: 9px;
  bottom: 0px;
  width: 0px;
  height: 0px;
  border-top: 10px solid transparent;
  border-right: 10px solid #3f93ed;
  border-bottom: 10px solid transparent;
}

.arrow-left.arrow-right {
  left: auto;
  top: 9px;
  right: -9px;
  bottom: 0px;
  border-left: 10px solid #3f93ed;
  border-right-style: none;
  opacity: 1;
}

.arrow-left.arrow-top {
  left: 0px;
  top: -9px;
  right: 0px;
  bottom: auto;
  display: block;
  margin-right: auto;
  margin-left: auto;
  border-left: 10px solid transparent;
  border-top-style: none;
  border-right-color: transparent;
  border-bottom-color: #3f93ed;
}

.next-button {
  margin-top: 25px;
}

.error-text {
  position: static;
  left: 0px;
  right: 0px;
  bottom: -30px;
  display: none;
  margin-top: 15px;
  padding-left: 0px;
  -webkit-transition: all 300ms ease;
  transition: all 300ms ease;
  color: #ef3938;
}

.hover-divider {
  width: 4px;
  height: 125px;
  margin-right: 15px;
  margin-bottom: 40px;
  border-radius: 2px;
  background-color: #52c5c2;
  box-shadow: 0 0 6px 0 hsla(0, 0%, 100%, 0.37);
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
}

.document-icon {
  position: absolute;
  right: -10px;
  bottom: -10px;
  width: 30px;
}

.document-list {
  margin-top: 9px;
  padding-left: 20px;
  font-weight: 300;
}

.numeric-text {
  font-size: 30px;
}

.document-details {
  position: absolute;
  display: none;
  width: 200px;
  height: auto;
  padding: 28px 5px 24px;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 1px 1px 3px 0 #000;
}

.close-details-x {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #000;
  font-size: 25px;
  font-weight: 600;
  text-decoration: none;
}

.close-details-x:hover {
  color: #5c5c5c;
}

.document-details-list {
  padding-left: 20px;
  color: #000;
  font-size: 12px;
  text-align: left;
}

.line-div {
  width: 100%;
  height: 2px;
  margin-bottom: 20px;
  background-color: #000;
}

/* Notification Styles */

.notification-item {
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  text-align: left;
  padding-bottom: 0px;
  margin-bottom: 0px;
  height: 100%;
  /* max-height: 450px; */
  overflow: auto;
}

.metrics {
  margin-left: 60px;
}

.metricsleft {
  float: left;
  height: 100%;
}
.metricsmiddle {
  float: right;
  height: 100%;
  margin-left: 160px;
}
.metricsright {
  float: right;
  height: 100%;
  margin-left: 200px;
}

.loadingBar {
  height: 20px;
  width: 250px;
  border-radius: 50px;
  border: 1px solid #333;
}

.bar {
  height: 100%;
  border-radius: 50px;
  transition: width 1s ease-in;
  background: rgb(0, 72, 132);
}

.notification-item h3 {
  margin-top: 10px;
  margin-bottom: 0px;
  color: #000;
  font-size: 1rem;
  line-height: 30px;
  font-weight: 300;
}

.notification-item .line-div {
  width: 100%;
  height: 1px;
  margin-bottom: 10px;
  background-color: #000;
}

.notification-list-actions {
  padding-left: 18px;
  font-size: 12px;
  height: 70%;
  overflow-y: auto;
  margin-bottom: 0px;
}

.notification-list-comments {
  padding-left: 18px;
  font-size: 12px;
  height: 47%;
  overflow-y: auto;
  margin-bottom: 0px;
}

.notification-list-missions {
  padding-left: 18px;
  font-size: 12px;
  height: 47%;
  overflow-y: auto;
  margin-bottom: 0px;
}

.notification-container:first-of-type {
  width: 100%;
  padding: 0px 10px 0px 0px;
  border-radius: 8px;
  box-shadow: none;
  height: 43%;
}

.notification-container {
  width: 100%;
  padding: 0px 10px 0px 0px;
  border-radius: 8px;
  box-shadow: none;
  height: 24%;
}

.notification-container:last-of-type {
  width: 100%;
  padding: 0px 10px 0px 0px;
  border-radius: 8px;
  box-shadow: none;
  height: 24%;
}

.notification-container._66 {
  width: 66.66666667%;
  padding: 10px;
}

.notification-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  width: 100%;
  padding-top: 50px;
  padding-left: 50px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
}

.org-notifications-members {
  /* width: -webkit-fill-available; */
  width: 70%;
}

.org-selector-page {
  background-color: lightgray;
  color: white;
  width: 8px;
  height: 100%;
  box-shadow: inset 2px 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-left: 1px solid darkgrey;
}

.add-org {
  background-color: #3ec4e5;
  color: white;
  width: 8px;
  height: 100%;
  box-shadow: inset 2px 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-left: 1px solid darkgrey;
  /* position: fixed; */
  /* width: 30px;
  height: 30px;
  margin-top: 270px; */
}

/* .add-org:hover {
  cursor: pointer;
} */

.org-logo-tab {
  width: 35px;
  height: 40px;
  background-color: lightgray;
  /* margin-top: 20px; */
  margin-left: 3px;
  position: relative;
  border-radius: 10%;
  box-shadow: 1px 0px 0px 0px darkgrey;
  border-right: 1px solid darkgrey;
}

.org-image:hover {
  cursor: pointer;
}

.org-image {
  position: fixed;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  margin-left: 2px;
  background-color: #333541;
  border-radius: 100%;
  color: white;
  text-align: center;
  line-height: 29px;
}

/* .second-org-logo-tab {
  width: 35px;
  height: 40px;
  background-color: lightgray;
  margin-top: 70px;
  margin-left: 3px;
  position: relative;
  border-radius: 10%;
  box-shadow: 3px 0px 0px 0px darkgrey;
  border-right: 1px solid darkgrey;
} */

/* .second-org-image {
  position: fixed;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  margin-left: 2px;
  background-color:#333541;
  border-radius:100%;
  color:white;
  text-align: center;
  line-height: 29px;
} */

/* .third-org-logo-tab {
  width: 35px;
  height: 40px;
  background-color: lightgray;
  margin-top: 120px;
  margin-left: 3px;
  position: relative;
  border-radius: 10%;
  box-shadow: 3px 0px 0px 0px darkgrey;
  border-right: 1px solid darkgrey;
} */

/* .third-org-image {
  position: fixed;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  margin-left: 2px;
  background-color:#333541;
  border-radius:100%;
  color:white;
  text-align: center;
  line-height: 29px;
} */

/* .fourth-org-logo-tab {
  width: 35px;
  height: 40px;
  background-color: lightgray;
  margin-top: 170px;
  margin-left: 3px;
  position: relative;
  border-radius: 10%;
  box-shadow: 3px 0px 0px 0px darkgrey;
  border-right: 1px solid darkgrey;
} */

/* .fourth-org-image {
  position: fixed;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  margin-left: 2px;
  background-color:#333541;
  border-radius:100%;
  color:white;
  text-align: center;
  line-height: 29px;
} */

/* .fifth-org-logo-tab {
  width: 35px;
  height: 40px;
  background-color: lightgray;
  margin-top: 220px;
  margin-left: 3px;
  position: relative;
  border-radius: 10%;
  box-shadow: 3px 0px 0px 0px darkgrey;
  border-right: 1px solid darkgrey;
} */

/* .fifth-org-image {
  position: fixed;
  width: 30px;
  height: 30px;
  margin-top: 5px;
  margin-left: 2px;
  background-color:#333541;
  border-radius:100%;
  color:white;
  text-align: center;
  line-height: 29px;
} */

.org-selector {
  display: flex;
}

.org-member-container {
  width: 100%;
  height: 100%;
  padding: 0px 10px 10px 0px;
  border-radius: 8px;
  box-shadow: none;
}

.org-members-header {
  display: flex;
  justify-content: space-between;
}

/* .org-member-item {
  margin-right: 0px;
  margin-left: 0px;
  padding-right: 10px;
  border-radius: 5px;
  background-color: #fff;
  color: #333;
  text-align: left;
  padding-bottom:0px;
  margin-bottom:20px;
  max-height: 450px;
  overflow: auto;
} */

.org-members-header h3 {
  margin-top: 10px;
  margin-bottom: 0px;
  color: #000;
  font-size: 1rem;
  line-height: 30px;
  font-weight: 300;
}

.org-member-container .line-div {
  width: 100%;
  height: 1px;
  margin-bottom: 10px;
  background-color: #000;
}

.members {
  /* width: max-content; */
}

.members-wrapper {
  width: 50%;
  height: 80%;
  /* overflow: auto; */
  padding-top: 15px;
  padding-left: 15px;
}

.members-container {
  overflow: auto;
  height: calc(100vh - 345px);
}

.quick-template-create {
  height: 100%;
}

.quickstart-wrapper {
  display: flex;
  flex-direction: column;
  /* height: 53%; */
}

.quickstart-container {
  padding: 15px 15px 0px 15px;
  height: 100%;
  width: 30%;
  background-color: lightgrey;
}

/* .create-mission {
  height: 26%;
} */

.org-selector-container {
  width: 10%;
  background-color: dimgrey;
  display: flex;
}

.org-selector-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.add-org-wrapper {
  align-self: flex-end;
  margin-right: 3px;
  margin-left: 2px;
}

.member-invite {
  margin-bottom: 10px;
}

.member-invite button {
  height: 50%;
  top: 25%;
  /* width: -webkit-fill-available; */
}

.notification-wrapper-org {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  width: 50%;
  padding-top: 15px;
  padding-left: 15px;
  height: 70%;
  /* -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center; */
  flex-direction: column;
}

.notification-members {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.org-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow: auto;
  width: 50%;
  padding-top: 20px;
  padding-left: 20px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  /* align-items: flex-start;
  -webkit-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center; */
  flex-direction: column;
}

.home-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  margin-left: 240px;
}

.home-org {
  display: flex;
  /* flex-direction: column; */
  /* width: 90%; */
}

.home-org-details {
  display: flex;
  flex-direction: column;
  /* padding: 20px 0px 0px 20px;
  box-shadow: 3px 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-left: 1px solid darkgrey;
  */
  width: -webkit-fill-available;
}

.home-org-wrapper {
  display: flex;
  flex-direction: row;
  padding: 20px 0px 0px 20px;
  box-shadow: 3px 0px 0px 0px rgba(0, 0, 0, 0.1);
  border-left: 1px solid darkgrey;
  width: -webkit-fill-available;
  justify-content: space-between;
}

.home-org-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.home-notification-button {
  margin-right: -26px;
  z-index: 5;
  /* padding-left: 11px; */
  height: min-content;
}

/* .home-notification-button:hover {
  cursor: pointer;
} */

.home-logo-name {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  padding-left: 20px;
}

.home-org-name {
  display: flex;
  flex-direction: column;
  padding-right: 10px;
}

.home-org-missions {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 86%;
  align-self: center;
}

/* .home-org-missions div {
  margin: 3px;
  border: 1px solid;
  padding: 5px;
} */

.home-org-members {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.mission-number {
  margin: 4px 22px 1px;
  /* border: 1px solid; */
  /* padding: 5px; */
  /* border-radius: 50%; */
  width: 35px;
  height: 35px;
  text-align: center;
  /* line-height: 25px; */
}

.mission-text {
  margin-top: 5px;
  text-align: center;
  font-size: 10px;
  line-height: normal;
  width: 80%;
}

.button {
  display: block;
}

.time-block-segment {
  width: 10px;
  height: 20px;
  margin-right: 1px;
  margin-left: 0px;
  background-color: #d6d6d6;
}

.time-block-segment.right-end {
  border-top-right-radius: 100%;
  border-bottom-right-radius: 100%;
}

.time-block-segment.left-end {
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
}

.time-block-segment.left-end.filled {
  background-color: #3ec4e5;
}

.time-block-segment.filled {
  background-color: #3ec4e5;
}

.top-microflow-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.main_logo {
  width: 30px;
  margin-right: 5px;
}

.login-div {
  width: 400px;
  padding: 10px 25px;
  background-color: rgba(51, 53, 65, 0.75);
  color: #fff;
  text-align: center;
}

.white {
  color: #fff;
}

.login-h1 {
  color: #fff;
  font-weight: 300;
}

.login-input {
  border-radius: 1px;
  background-color: #fff;
  color: #8e8e8e;
}

.login-input:focus {
  border: 1px solid blue;
  background-color: #fff;
}

.remember-me-checkbox {
  color: #fff;
  text-align: left;
}

.login-submit {
  text-decoration: none;
  text-transform: uppercase;
}

.forgot-password {
  display: block;
  color: #fff;
  margin-top: 10px;
  text-decoration: none;
}

.forgot-password:hover {
  text-decoration: underline;
  color: #3ec4e5;
}

.modal-body {
  position: fixed;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 99909;
  display: none;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
}

.modal-container {
  display: block;
  max-width: 785px;
  margin-right: auto;
  margin-left: auto;
  background-color: #fff;
}

.form-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.form-input {
  border-radius: 5px;
}

.workspace-header {
  position: static;
  left: 240px;
  top: 60px;
  margin-top: 5px;
  margin-bottom: 5px;
  color: rgba(0, 0, 0, 0.87);
  font-size: 1.25rem;
  font-weight: 400;
  line-height: 1.35417em;
  text-align: left;
  right: 5px;
}

.white-bar {
  width: 1px;
  height: 30px;
  background-color: #fff;
}

.create-button {
  margin-top: 10px;
  margin-left: 10px;
}

.days-left {
  display: block;
  margin-right: auto;
  margin-left: auto;
  color: #aaa;
  font-size: 18px;
  line-height: 18px;
  font-weight: 200;
}

.chip {
  margin: 3px;
  padding-right: 5px;
  padding-left: 5px;
  border-radius: 20px;
  background-color: #fff;
  font-size: 12px;
}

.edit-button-container {
  position: absolute;
  top: 15px;
  right: 0px;
}

.comments-box {
  position: absolute;
  left: 0px;
  top: 0px;
}

.comments-box.document {
  left: -15px;
  top: -15px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 75px;
  height: 75px;
  padding-top: 0px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  opacity: 0.5;
  -webkit-transition: opacity 250ms ease;
  transition: opacity 250ms ease;
  color: #fff;
  font-size: 12px;
}

.comments-box.document:hover {
  opacity: 0.75;
}

.comments-box.document.bottom-box {
  left: auto;
  top: auto;
  right: 0%;
  bottom: -25%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: end;
  -webkit-align-items: flex-end;
  -ms-flex-align: end;
  align-items: flex-end;
}

.microflow-message-box {
  border-radius: 8px;
  background-color: #ededef;
  position: absolute;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 9;
  display: none;
  overflow: scroll;
  margin: 3px;
  padding: 20px 25px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.close-message-box {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 99;
  width: 25px;
  height: 25px;
  padding: 3px;
  cursor: pointer;
}

.message-paragraph {
  text-align: left;
}

.header-container {
  position: absolute;
  left: 240px;
  top: 15px;
  text-align: left;
  max-width: calc(100vw - 400px);
}

.header-wrapper {
  display: flex;
}

.flag-wrapper {
  background-color: #b8ded5;
  /* margin-bottom: 30px; */
  padding: 5px 15px 5px 20px;
}

.flagend {
  position: relative;
  left: -20px;
  top: 15px;
  height: 108px;
}

.status-text {
  display: inline-block;
  margin-top: 0px;
  margin-bottom: 0px;
  padding-top: 5px;
  padding-bottom: 5px;
  float: left;
  color: black;
  font-size: 17px;
  line-height: 18px;
  font-weight: 300;
  text-align: left;
  text-transform: capitalize;
}

.header-status-container {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  margin-top: 5px;
  padding-left: 0px;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}

.action-button {
  margin-right: 0px;
  margin-left: 0px;
  font-size: 17px;
  line-height: 18px;
}

.tab-pane-tab-1 {
  overflow: scroll;
  height: 400px;
}

.description-container {
}

span.description-paragraph {
  border: 1px solid rgba(153, 153, 153, 0.4);
  border-radius: 6px;
  color: #666;
  padding: 5px;
}

.description-paragraph.italic {
  font-style: italic;
  color: #666;
}

.comments-container {
  margin-top: 5px;
}

.comment-item {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}

.comment-date {
  display: inline-block;
  margin-bottom: 0px;
  border-style: none none dotted;
  border-bottom-width: 1px;
  border-bottom-color: #cecece;
}

.comment-date-container {
  margin-bottom: 8px;
}

.comment-icon {
  width: 35px;
  height: 35px;
  border-radius: 100%;
}

.comment-top-container {
  width: 100%;
  padding-right: 5px;
  padding-left: 5px;
  text-align: left;
}

.comment-name {
  display: inline-block;
  margin-right: 10px;
  margin-left: 0px;
  font-weight: 700;
}

.comment-time {
  display: inline-block;
  color: grey;
}

.comment-add-container {
  margin-top: 10px;
}

.comment-action-container {
  position: static;
  top: 0px;
  right: 0px;
  display: inline-block;
  float: right;
}

.comment-action-icon {
  width: 20px;
}

.comments-badge {
  position: absolute;
  right: -10px;
  bottom: -10px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 20px;
  height: 20px;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(134, 37, 37, 0.3);
  border-radius: 100%;
  background-color: #f50057;
  color: #000;
}

@media (max-width: 991px) {
  .bottomstatusbar {
    left: 20%;
  }
  .bottomstatusbar.artifact_status_bar {
    left: 10%;
    right: 10%;
    display: none;
    margin-right: auto;
    margin-left: auto;
  }
  .bubblelink {
    left: 31%;
  }
  .bubblelink.link2 {
    left: 44%;
  }
  .bubblelink.link3 {
    left: 57%;
  }
  .navbar__left {
    width: 33.33333333%;
  }
  .navbar__center {
    width: 33.33333333%;
  }
  .navbar__right {
    width: 33.33333333%;
  }
  .navbar_icon_image {
    height: 17px;
  }
  .notification_badge {
    top: -3px;
    right: -3px;
    width: 15px;
    height: 15px;
    font-size: 8px;
  }

  .panel_body {
    padding-left: 15px;
  }
  .navbar_logo {
    width: 188px;
  }

  .org-menu {
    min-width: 200px;
  }

  .drawer-list-item {
    font-size: 12px;
    text-align: left;
  }
  .left-tabs {
    width: 200px;
  }
  .left-tabs-menu {
    padding-right: 5px;
    padding-left: 5px;
  }
  .left-tabs-link {
    text-align: left;
  }
  .left-tabs-link.w--current {
    text-align: left;
  }
  .notification-container {
    width: 39%;
  }
  .chip {
    margin: 4px;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 30px;
    background-color: #fff;
  }
}

@media (max-width: 767px) {
  .bottomstatusbar {
    left: 5%;
    right: 5%;
    bottom: 10px;
  }
  .bottomstatusbar.artifact_status_bar {
    left: 5%;
    right: 5%;
    min-width: 500px;
  }
  .actionbutton {
    height: 35px;
  }
  .navbar__left {
    width: 30%;
  }
  .header_dropdown_list {
    position: absolute;
  }
  .header_dropdown_list.w--open {
    position: relative;
    left: auto;
    top: auto;
    right: auto;
    display: block;
  }
  .navbar__center {
    width: 36%;
  }
  .navbar__right {
    padding-top: 42px;
    padding-left: 5px;
    background-color: rgba(0, 0, 0, 0.9);
  }
  .navbar__icon_link {
    display: block;
    margin-right: 0px;
    margin-left: 0px;
    padding: 15px;
    text-align: left;
  }
  .navbar__icon_link.main_icon {
    padding: 2px;
  }
  .navbar_icon_image {
    display: inline-block;
    width: 30px;
    height: auto;
    margin-right: auto;
    margin-left: auto;
  }
  .navbar_icon_image.main_image {
    width: auto;
    height: 30px;
  }
  .notification_badge {
    left: -8px;
    top: -8px;
    right: auto;
    width: 25px;
    height: 25px;
    font-size: 12px;
  }
  .navbar_dropdown_toggle {
    padding: 0px;
  }
  .toggle_wrap {
    display: inline-block;
    width: 32px;
  }
  .toggle_link.navbar__icon_link {
    display: block;
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    text-align: left;
  }
  .document_source_logo {
    height: 80px;
  }
  .hamburger_menu {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  .navbar__close {
    position: absolute;
    top: 5px;
    right: 5px;
    display: block;
    width: 30px;
    height: 30px;
  }
  .navbar__mobile_menu_text {
    display: inline-block;
    margin-left: 10px;
    color: #cecece;
    font-size: 15px;
  }
  .notification-container {
    width: 50%;
  }
  .notification-container._66 {
    width: 50%;
  }
}

@media (max-width: 479px) {
  .bottomstatusbar {
    width: 100%;
    min-width: auto;
    padding-right: 2px;
    padding-left: 2px;
  }
  .bottomstatusbar.artifact_status_bar {
    left: 1%;
    right: 1%;
    min-width: 350px;
  }
  .processbutton {
    margin-top: 0px;
  }
  .actionbutton {
    height: 23px;
    margin-right: 5px;
    margin-bottom: 0px;
    margin-left: 5px;
  }
  .bottomstatusleft {
    padding-top: 0px;
  }
  .bottomstatusright {
    display: block;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .navbar {
    padding: 5px 0px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
  }
  .navbar__left {
    display: block;
    width: 100%;
    padding-left: 15px;
    border-style: none none solid;
    border-bottom-width: 1px;
    border-bottom-color: #fff;
    text-align: left;
  }
  .navbar__center {
    width: 79%;
    padding-left: 0px;
  }
  .navbar__icon_link.main_icon {
    margin-right: 8px;
    margin-left: 8px;
  }
  .navbar_center_main {
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .panel {
    min-width: 201px;
  }
  .panel.panel_new_document {
    left: 3%;
    z-index: 1;
    min-width: 200px;
  }
  .panel_item {
    font-size: 13px;
    line-height: 13px;
  }
  .document_source_logo {
    height: 70px;
  }
  .hamburger_menu {
    position: static;
    padding: 10px;
  }
}

.description-paragraph {
  display: block;
  width: 100%;
  text-align: left;
  min-height: 100px;
  font-size: 12px;
  line-height: 16px;
  font-family: Arial, Helvetica, sans-serif;
}

.emptystate {
  text-align: center;
  list-style-type: none;
  font-size: 12px;
  color: #000;
}

.emptystate div {
  position: fixed;
  z-index: 1000;
  left: 48%;
  top: 50%;
}

.emptystate li {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 0px;
  margin-left: -18px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: left;
}

.microflow-subtitle {
  margin: 0px auto 5px;
  -webkit-transition: all 200ms ease;
  transition: all 200ms ease;
  color: #333541;
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  text-align: center;
  cursor: default;
}

.RIEInput-editing {
  width: 300px;
}

.missionFont {
  font-family: Arial, Helvetica, sans-serif;
}

.subheaderText {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 12px;
  text-align: center;
  line-height: 15px;
}

#root {
  height: 100%;
}

.body,
html,
body {
  height: 100%;
  margin: 0;
  font-family: Roboto, RobotoDraft, Helvetica, Arial, sans-serif;
  overflow: hidden;
}

.bottomNavigation {
  position: fixed !important;
  bottom: 0px;
  left: 0px;
  right: 0px;
  z-index: 2;
}
.workspace {
  /* overflow: auto; */
  /* padding-top:55px; */
}

.left-tabs button {
  width: 100% !important;
  color: #333 !important;
  text-transform: none !important;
}
.left-tabs button div div {
  height: auto !important;
  padding: 10px !important;
  display: block !important;
}

.panel__search_container button {
  width: 48px !important;
}
.left-tabs-content {
  background-color: #4f4f4f;
  overflow-y: auto;
  overflow-x: hidden;
}

.drawer-list > li > ul > li {
  font-size: 11px;
  transition: all 200ms;
}

.drawer-list > li > ul > li > a:hover {
  text-decoration: underline !important;
}

.drawer-list-item > ul {
  padding-left: 15px;
}

.badge {
  width: 100%;
  padding: 0px !important;
}

.bigblue,
.smallblue,
.smallwhite {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
}

.panel-button {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.panel-button div span {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.panel-button div div {
  transition: 250ms all;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
}

.panel-button div div:hover {
  background-color: #e7e7e7 !important;
}

.panel-button div div:hover span {
  color: white !important;
}
.w-input:focus,
.w-select:focus {
  border-color: white;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.5);
}

/*** NAV SEARCH ***/
/* Search icon by IcoMoon, made with http://icomoon.io/app/ */
.sb-search {
  position: relative;
  width: 0%;
  min-width: 40px;
  height: 40px;
  float: right;
  overflow: hidden;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  transition: width 0.3s;
  -webkit-backface-visibility: hidden;
}

.sb-search-input {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  outline: none;
  background: #fff;
  width: 100%;
  height: 40px;
  margin: 0;
  z-index: 10;
  padding: 20px 65px 20px 20px;
  font-family: inherit;
  font-size: 14px;
  color: black;
}

.sb-search-input::-webkit-input-placeholder {
  color: #4f4f4f;
}

.sb-search-input:-moz-placeholder {
  color: #4f4f4f;
}

.sb-search-input::-moz-placeholder {
  color: #4f4f4f;
}

.sb-search-input:-ms-input-placeholder {
  color: #4f4f4f;
}

.sb-icon-search,
.sb-search-submit {
  width: 50px;
  height: 50px;
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  padding: 0;
  margin: 0;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.sb-search-submit {
  background: #fff; /* IE needs this */
  -ms-filter: 'progid:DXImageTransform.Microsoft.Alpha(Opacity=0)'; /* IE 8 */
  filter: alpha(opacity=0); /* IE 5-7 */
  opacity: 0;
  color: transparent;
  border: none;
  outline: none;
  z-index: -1;
}

.sb-icon-search {
  color: #fff;
  background: black;
  z-index: 90;
  font-size: 22px;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  -webkit-font-smoothing: antialiased;
  padding-top: 5px;
}

/* Open state */
.sb-search.sb-search-open,
.no-js .sb-search {
  width: 100%;
}

.sb-search.sb-search-open .sb-icon-search,
.no-js .sb-search .sb-icon-search {
  background: #4f4f4f;
  color: #fff;
  z-index: 11;
}

.sb-search.sb-search-open .sb-search-submit,
.no-js .sb-search .sb-search-submit {
  z-index: 90;
}

/** END SEARCH **/

.move-right {
  transform: translateX(100%);
}
.panel__search_field {
  transition: 250ms all;
}
.panel__search_field:hover,
panel__search_field:focus {
  background-color: whitesmoke;
}

.microflow {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 6px, rgba(0, 0, 0, 0.12) 0px 1px 4px;
}
.scroll-wrapper {
  width: 90%;
}
.microflow-wrapper {
  overflow-x: auto;
}
.microflow,
.name-text,
.title-text,
.microflow-bottom-container,
.start-date-container,
.end-date-container,
.process-tooltip {
  -webkit-transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}
.microflow {
  -webkit-transition: all 750ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  transition: all 750ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
}

/*CUSTOM SCROLL */
/* Let's get this party started */
::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 2px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 10px;
  border-radius: 10px;
  width: 90%;
}

/* Handle */
::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: rgba(53, 53, 53, 0.5);
  -webkit-box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.5);
}
::-webkit-scrollbar-thumb:window-inactive {
  background: rgba(255, 0, 0, 0.4);
}

.external {
  border: 4px solid #e2ca40;
}
.count-container {
  float: right;
}

.drawer-tabs {
  padding-left: 4px;
  padding-right: 4px;
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  background-color: #646565 !important;
}

/*** ELLIPSES LOADER **/
@-webkit-keyframes lds-ellipsis3 {
  0%,
  25% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    left: 100px;
  }
  100% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0%,
  25% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    left: 100px;
  }
  100% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@-webkit-keyframes lds-ellipsis {
  0% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  25% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    left: 100px;
  }
  75% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    left: 168px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
@keyframes lds-ellipsis {
  0% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  25% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    left: 100px;
  }
  75% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    left: 168px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}
.lds-ellipsis {
  position: relative;
}
.lds-ellipsis > div {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 32px;
  height: 32px;
}
.lds-ellipsis div > div {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #f00;
  position: absolute;
  top: 100px;
  left: 32px;
  -webkit-animation: lds-ellipsis 1.6s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  animation: lds-ellipsis 1.6s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
}
.lds-ellipsis div:nth-child(1) div {
  -webkit-animation: lds-ellipsis2 1.6s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  animation: lds-ellipsis2 1.6s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  background: #ffffff;
}
.lds-ellipsis div:nth-child(2) div {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
  background: #ffffff;
}
.lds-ellipsis div:nth-child(3) div {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
  background: #ffffff;
}
.lds-ellipsis div:nth-child(4) div {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  background: #ffffff;
}
.lds-ellipsis div:nth-child(5) div {
  -webkit-animation: lds-ellipsis3 1.6s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  animation: lds-ellipsis3 1.6s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  background: #ffffff;
}
.login-top-container {
  width: 100%;
}
/* .manual-auth {
    margin-left: 375px;
    margin-right: 375px;
} */
.forgot-text {
  text-align: justify;
  margin-bottom: 20px;
}
/* #forgotEmail {
    margin-left: 275px;
    margin-right: 275px;
} */
.go-back {
  cursor: pointer;
  /* margin-left: 425px;
    margin-right: 425px; */
  align-content: center;
}
.signin-title {
  display: flex;
}
.signin-input {
  border-color: white;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.5);
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #333333;
  vertical-align: middle;
  background-color: black;
  border: 1px solid #cccccc;
}
.signin-input:focus {
  border-color: white;
  box-shadow: 1px 1px 10px 0 rgba(0, 0, 0, 0.5);
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 12px;
  margin-bottom: 10px;
  font-size: 14px;
  line-height: 1.428571429;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid #cccccc;
}
.remember-check-box {
  text-align: left;
  /* margin-top: 20px; */
  /* margin-bottom: -5px; */
}
.pwd-forgot {
  cursor: pointer;
  margin-top: 20px;
}
.reset-text {
  padding: 200px 0;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  line-height: 1.6;
}
.intro-text {
  margin: auto;
  margin-bottom: 20px;
}
.login-logo-image {
  width: 100px;
}
.flex {
  padding-top: 80px !important;
}
.drawer-tabs {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -webkit-justify-content: flex-start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.chip-wrapper,
.search-results-container {
  margin-top: 5px;
  margin-bottom: 5px;
}
.chip-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.chip {
  margin: 2px !important;
  background-color: #c7c7c7 !important;
  transition: all 200ms;
  border-radius: 2px;
}
.chip:hover {
  background-color: #98dff0 !important;
}
.chip-smaller {
  margin: 3px !important;
}
.chip > span {
  font-size: 12px !important;
  color: black !important;
  font-weight: 300 !important;
  font-family: Arial, Helvetica, sans-serif;
}
.chipActive {
  background-color: #3fc4e5 !important;
  margin: 2px !important;
  border-radius: 2px;
}
.chipActive > span {
  font-size: 12px !important;
  color: #fff !important;
  font-family: Arial, Helvetica, sans-serif;
}
.left-tabs-content {
  margin-top: 10px;
}
.close-container {
  position: absolute !important;
  width: 10px;
  height: 10px;
  opacity: 0.4;
}
.smallwhite:hover {
  box-shadow: 0 0 6px 0 #52c5c2;
}

.emoji-icon {
  opacity: 0.4;
  color: #333541;
  cursor: pointer;
}
.emoji-icon:hover {
  opacity: 1;
}

.edit-microflow.more-height:hover > .process-tooltip {
  display: block;
}

.datePicker div input {
  text-align: center !important;
  height: 30px !important;
  cursor: pointer !important;
  color: #fff !important;
}
.edit-people-item {
  cursor: pointer;
  color: black;
}
.usermenu {
  padding: 0 !important;
}

/*CONTROL NEW PROCESS FLOW -- DELETE IF NECESSARY */

.new-process-canvas {
  display: flex;
}

.new-process-wrapper {
  display: block;
}

.new-microflow-wrapper {
  display: block;
}

.microflow-setup-wrapper {
  display: block;
}

.error-text {
  display: block;
}
.hover-divider {
  display: none;
}
/*END*/

.initiate-button {
  position: absolute;
  right: 100px;
  top: 100px;
}

.author,
.reviewer,
.contributor,
.receiver,
.signer,
.counter-signer,
.counterSigner,
.individual,
.proposer,
.counterProposer,
.approver,
.payee,
.payor,
.decider {
  border: 3px solid #3ec4e5;
}
.requester,
.orchestrator {
  border: 3px solid #045196;
}
.watcher,
.subjectExpert {
  border: 3px solid green;
}
.rejected {
  border: 3px solid red !important;
}
.completed {
  border: 3px solid #f7bb3a;
}
.document-completed {
  background: #f7bb3a !important;
}
.nonActive > .requester,
.nonActive > .orchestrator,
.nonActive > .reviewer,
.nonActive > .author,
.nonActive > .reviewer,
.nonActive > .contributor,
.nonActive > .receiver,
.nonActive > .signer,
.nonActive > .counter-signer,
.nonActive > .counterSigner,
.nonActive > .individual,
.nonActive > .proposer,
.nonActive > .counterProposer,
.nonActive > .approver,
.nonActive > .watcher,
.nonActive > .subjectExpert,
.nonActive > .completed,
.nonActive > .rejected,
.nonActive > .decider {
  border-width: 1px !important;
}
.final-submenu {
  padding-bottom: 40px;
}
.document {
  position: relative;
  z-index: 2;
}
.document-icon {
  position: absolute;
  width: 32px;
  right: -14px;
  bottom: -12px;
}
.mini-icon {
  width: 20px;
  right: -10p;
  bottom: -10px;
}
.mini-document {
  border-width: 1px !important;
}
.datePicker div div {
  bottom: 7px !important;
  left: 5px !important;
}
.info-text {
  color: black;
  padding-left: 25px;
  margin-top: 5px;
  font-weight: 200;
  padding-bottom: 30px;
}
.info-text li {
  margin-bottom: 5px;
}

.workitems-microflow-container {
  display: flex;
  justify-content: flex-start;
}
.dropbox-logo {
  width: auto;
  height: auto;
  margin-top: 0px;
}
.google-logo {
  width: 49px;
  height: 49px;
  margin: 10px;
  cursor: pointer;
}
.google-logo-side {
  width: auto;
  height: auto;
  margin-top: 0px;
}
.document-list {
  font-weight: 300;
}
.numeric-text {
  font-size: 35px;
  font-weight: 500;
}

.main-title {
  position: absolute;
  top: 65px;
  left: 255px;
  font-size: 25px;
  font-weight: 500;
  margin-top: 5px;
  color: #333;
}
.process-tooltip {
  display: none;
}
li.warning {
  /*list-style-image: url('../images/Warning.png');*/
}
li.alert {
  /*list-style-image: url('../images/Alert.png');*/
  color: #333541;
}
li.alert a,
li.warning a,
ul.notification-list li a {
  /*text-decoration: underline;*/
  cursor: pointer;
}
ul.notification-list li a:hover {
  color: #3ec4e5;
}
li.overdue {
  font-weight: 400;
}
.left-tabs-content {
  overflow-y: auto !important;
}
/*ul.no-underline li span {
    text-decoration:none;
    transition: all 250ms;
}
ul.no-underline li span:hover {
    text-decoration:underline;
}*/
textarea.main-title {
  color: white;
  background-color: #646565;
  width: 400px;
}

.document-details {
  display: block;
  width: auto;
  z-index: 999999;
}

/* ------MICROFLOW FRAMEWORK-------- */
.microflowsWrapper {
  margin-bottom: -50px;
  width: 100%;
  overflow-x: auto;
  display: flex;
  justify-content: flex-start;
}

.microflowWrapper1 {
  position: relative;
  z-index: 1;
}

.microflowWrapper1.active {
}

.microflowWrapper2 {
  /* min-height: 450px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 115px;
  padding: 3px 10px;
  flex: 0 1 auto;
  transition: width 250ms;
}

.microflowWrapper2.active {
  width: 360px;
}
/* ------MICROFLOW FRAMEWORK-------- */

/* ------MICROFLOW BOTTOM-------- */
.microflowBottomContainer {
  display: none;
  padding-top: 10px;
  justify-content: space-between;
  align-items: flex-start;
  transition: all 250ms;
  overflow: hidden;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.microflowBottomContainer.active {
  display: flex;
}

.datePicker {
  visibility: hidden;
  /* display: none; */
  /* display: none; */
  /* width: 0; */
  /* transition: all 250ms; */
  transform-origin: center center;
  transform: scale(0);
  transition: scale 250ms 0ms, visibility 0ms 250ms;
}

.datePicker.active {
  visibility: visible;
  /* display: block; */
  /* display: block; */
  /* width: auto; */
  /* opacity: 1; */
  /* transition: all 250ms; */
  transform-origin: center center;
  transform: scale(1);
  transition: scale 250ms 0ms;
}

/* ------MICROFLOW BOTTOM-------- */

/* ------MICROFLOW CENTER-------- */
.microflowCenter {
  position: relative;
  display: flex;
  min-height: 110px;
  padding: 10px 5px;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 8px;
  background-color: #353535;
  cursor: pointer;
  transition: background-color 250ms;
  height: 100%;
}

.microflowCenter.isDragging {
  background-color: #484848;
  transition: background-color 250ms;
}

.edit-microflow.isDragging {
  /*border: 1px solid black;*/
}

.microflowCenter__wrapper {
  /* background-color: red; */
  position: relative;
  width: 100%;
  height: 115px;
  transition: all 250ms;
}

.microflowCenter__people {
  background-color: red;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  padding: 2px;
  background-color: #eef2e5;
  border: 1px solid #e1e8d0;
  border-radius: 6px;
}
/* ------MICROFLOW CENTER-------- */

/* ------MICROFLOW USER-------- */
.avatar__wrapper {
  min-height: 52px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.avatar__wrapper.nonActive {
  min-height: auto;
}

.avatar__border {
  border-radius: 50%;
}

.avatar__img {
  border-radius: 50%;
  width: 100%;
  height: auto;
}

.avatar__wrapper .avatar {
  cursor: pointer;
  width: 50px;
  height: 50px;
  transition: all 250ms;
}
.avatar {
  margin-left: 0px;
}

.avatar__wrapper .avatarDraggingOver {
  transform: scale(1.1);
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.25);
  width: 50px;
  height: 50px;
  transition: all 250ms;
}

.smallblue {
  transition: all 250ms;
}

@keyframes close23 {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  99% {
    transform: scale(0);
    opacity: 0;
  }
  100% {
    /* display: none; */
    transform: scale(0);
    opacity: 0;
  }
}

.user__text div {
  animation: close23 250ms normal;
  animation-fill-mode: both;
}
.user__text.active div {
  animation: close23 250ms reverse;
  animation-fill-mode: both;
}

/* ------MICROFLOW USER-------- */

/* ------MICROFLOW DOCUMENT------- */
.microflowCenter__people .document {
  transition: all 250ms;
}

.microflowCenter__people .artifact-text.active {
  opacity: 1;
  transform-origin: center center;
  transform: scale(1);
  transition: all 250ms;
}

.microflowCenter__people .artifact-text {
  opacity: 0;
  transform-origin: center center;
  transform: scale(0);
  transition: all 250ms;
}
/* ------MICROFLOW DOCUMENT------- */

.microflowCenter__people .grid50 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 auto;
  position: relative;
}

.microflowCenter__people .grid33 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 auto;
  position: relative;
}

.microflowCenter__people .grid100 {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  flex: 1 1 auto;
  position: relative;
}
.comments-box.document {
  z-index: 1;
  position: absolute;
  cursor: pointer;
}
.grid100 .comments-box {
  top: -25%;
  left: 25%;
  right: auto;
}
.grid100 .comments-box.bottom-box {
  right: 30%;
  left: auto;
}
.grid33 .comments-box.document {
  left: 0%;
  right: auto;
}
.grid33 .comments-box.document.bottom-box {
  right: 0%;
  left: auto;
}

.microflowWrapper1.active .microflow-h3 {
  font-size: 23px;
}

.artifact-text {
  color: white;
}
.numeric-text {
  color: white;
}
.nav-icon {
  padding: 9px !important;
}
.login-body {
  background-size: cover;
  background-image: url(../images/login-background.png);
}

.invite-link {
  float: right;
  cursor: pointer;
  font-size: 14px;
  color: #333541;
}

.invite-link:hover {
  float: right;
  cursor: pointer;
  font-size: 14px;
  color: #3ec4e5;
}

.login-bottom {
  display: flex;
  justify-content: space-between;
}

.logologin {
  width: auto;
  max-width: 230px;
  margin: auto;
}
.toolbar {
  padding: 0px 12px !important;
}
.dropdownmenu div div {
  padding-left: 0px !important;
  padding-right: 20px !important;
}
.dropdownmenu div button {
  padding-left: 0px !important;
  padding-right: 0px !important;
  right: 0px !important;
  width: 20px !important;
}

.document {
  color: white;
}

.document.isDragging {
  transform: scale(1.1);
  width: 75px;
  height: 75px;
  transition: all 250ms;
}

.invite-dialog {
  text-align: center;
  overflow: hidden;
}
.invite-dialog h3 {
  text-align: center;
  color: #074982 !important;
}
.forgot-password {
  cursor: pointer;
}
.login-div {
  margin-top: 100px;
}
.small-space {
  margin-right: 5px;
}

input.workspace-header {
  min-width: 300px;
  padding: 0px;
  border: none;
}
.workspace-header {
  height: 28px;
  margin: 0px;
  margin-right: 5px;
  white-space: nowrap;
}

input.sidebar-user-input {
  min-width: 100px;
  color: black;
}

.sidebar-user-input {
  padding-right: 3px;
}

.microflow-message-box {
  cursor: default;
  margin: 0px;
  display: block;
  overflow: auto;
  overflow-x: hidden;
  background-color: #e1e8d0;
}
.active-avatar {
  min-height: 62px;
}
.active-avatar .avatar {
  height: 62px;
  width: 62px;
}
.active-name {
  font-size: 15px;
  font-weight: 500;
}
.active-title {
}

.search-results-container .active {
  font-weight: bold;
}

.red-cursive {
  font-size: 13px;
  color: red;
  font-style: italic;
  display: inline-block;
  margin-left: 10px;
}

.notification-list-item {
  /* margin-bottom: 8px; */
}

.comment-action-container {
  display: none;
  opacity: 0;
  transition: all 200ms;
}
.comment-top-container:hover .comment-action-container {
  opacity: 1;
  display: block;
}
.comment-edited-text {
  margin-left: 5px;
  font-style: italic;
}
.comment-update-input {
  margin-bottom: 0px;
}

.notification-container.flex45 {
  width: 45%;
  margin-right: 20px;
}
.new-winflow-button-container {
  margin-bottom: 15px;
}

.panel-summary {
  min-height: 4vh !important;
  /* background-color: #fff !important; */
  height: 4vh;
}
/*
.panel-summary div {
    margin:10px 0px!important;
}
*/
.flex-form {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  padding: 20px;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
}
.flex50 {
  width: 50%;
  padding: 10px;
}
.label {
  text-align: left;
}
.starter-template-item {
  max-height: 500px;
  overflow: auto;
}
.microflowWrapper2.active .microflowBottomContainer {
  display: flex;
}
.draft-container {
  max-height: 550px;
  overflow: auto;
}
.short-container {
  max-height: 200px;
  overflow: auto;
}
.show-log-button {
  float: right;
  margin-top: -9px !important;
}
.hidden {
  display: none;
}
.noCursor {
  cursor: default !important;
}
.work-item-details {
  text-align: left;
}
.work-item-details ul {
  padding-left: 10px;
  padding-top: 10px;
  list-style-type: none;
}

.work-item-details-title {
  font-weight: 400;
  margin-left: 10px;
}
.completed-motion-timeline {
  width: 100%;
  height: 20px;
  border-radius: 8px;
  background-color: #414141;
}

.delete-draft-template-container {
  display: none;
  opacity: 0;
  transition: all 200ms;
  float: right;
  position: relative;
  top: 3px;
}

.list_item:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.list_item:hover .delete-draft-template-container {
  opacity: 1;
  display: block;
}

.delete-work-item {
  display: none;
  opacity: 0;
  transition: all 200ms;
  float: right;
  position: relative;
  bottom: 23px;
}

.edit-work-items:hover .delete-work-item {
  opacity: 1;
  display: block;
}

.upload-logo {
  height: 50px;
  width: 50px;
  margin: 10px;
  cursor: pointer;
}
.picker.picker-dialog {
  z-index: 2000;
}
.status-text {
  margin-right: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 15px;
  line-height: 15px;
}

.header-status-container {
  white-space: nowrap;
}
.description-text {
  margin-right: 5px;
}
.editable-status {
  text-transform: none;
}
input.editable-status {
  width: 300px;
}
.mobile-view {
  position: fixed;
  left: 0%;
  top: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 9999999;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #004884;
}

.mobile-view-text {
  width: auto;
  max-width: 400px;
  color: #fff;
  font-weight: 200;
}
/*ul.no-underline li:hover {
    text-decoration: none;
    cursor: auto;
}
ul.no-underline li span:hover {
    text-decoration: underline;
    cursor: pointer;
}
ul.no-underline li .delete-draft-template-container span:hover {
    text-decoration: none;
}*/
.flex-form {
  padding: 5px;
}
.dialogActions {
  display: flex;
}

.dialogActions > Button:first-child {
  margin-right: auto;
}
.winflow-description-container {
  width: 100%;
  color: black;
}
.winflow-title-container {
  width: 100%;
}
.second-line {
  font-size: 12px;
  line-height: 12px;
  color: #333333;
}

#form-dialog-title h6 {
  line-height: 22px !important;
}

.brand {
  display: flex;
  position: absolute;
  align-items: center;
  right: 25%;
  top: 125px;
  max-width: 450px;
  width: auto;
}

.brand img {
  width: auto;
  height: 90px;
}

@media screen and (max-width: 1250px) {
  .brand {
    right: 8%;
    top: 125px;
  }

  .brand img {
    width: auto;
    height: 90px;
  }
}

@media screen and (max-width: 1040px) {
  .brand {
    display: none;
  }
}

.star-graphic {
  width: 100px;
  float: left;
  z-index: 0;
  margin-bottom: 15px;
}

.orgSetting-container {
  display: flex;
  margin-bottom: 10px;
  padding-left: 20px;
}

.companyLogo {
  float: left;
  background-color: #333541;
  border-radius: 100%;
  color: white;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 10px;
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  font-size: 40px;
  margin-top: 0px;
}

.companyLogo:hover {
  cursor: pointer;
}

.avatarLogo {
  width: 60px;
  height: 60px;
  margin-bottom: 30px;
}

.x-icon {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.blue-link {
  cursor: pointer;
  color: #333541;
  font-weight: bold;
  margin: 0;
  display: inline;
}

.blue-link:hover {
  color: #3ec4e5;
}

.profile-tip {
  text-align: center;
  margin-top: 10px;
}

.action-header {
  display: inline-block;
  margin-right: 5px;
}

.info-icon {
  opacity: 0.5;
  font-size: 18px;
}

.missionFont::-webkit-scrollbar-thumb {
  background: gray;
}

.avatarPadding {
  margin-top: 30px;
  margin-bottom: 30px;
  height: 190px;
  width: 190px;
  align-self: center;
}

.mini-profile-picture {
  width: 52px;
  height: 52px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  /* margin-top: 30px; */
  position: absolute;
  left: 10px;
  cursor: pointer;
}

.mini-reload {
  width: 52px;
  height: 52px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  /* margin-top: 30px; */
  position: absolute;
  left: 180px;
  cursor: pointer;
  background-color: white;
}

.mini-lang {
  border-radius: 4px;
  position: absolute;
  width: 21px !important;
  height: 15px !important;
  left: 162px;
  top: 210px;
}

.profile-picture {
  margin-top: 15px;
  /* width: 70%; */
  height: 152px;
  width: 152px;
  cursor: pointer;
  border: 3px solid #ffffff;
  border-radius: 50%;
}

.org-picture {
  margin-top: 15px;
  /* width: 70%; */
  height: 152px;
  width: 152px;
  cursor: pointer;
  border: 3px solid #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
  font-size: 80px;
}

.org-picture-notification {
  margin-top: 15px;
  /* width: 70%; */
  height: 152px;
  width: 152px;
  /* cursor: pointer; */
  border: 3px solid #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  font-weight: 600;
  color: white;
  font-size: 80px;
}

.footer-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 5px;
  justify-content: space-between;
}

.scrollBarOver {
  justify-content: center;
  height: 200px;
  overflow: auto;
}

.dropbox-logos {
  width: auto;
  height: auto;
}

.searchText {
  color: white;
  text-align: start;
  margin-left: 10px;
  margin-top: 10px;
}

.instructionsComplete {
  background-color: #fcca5f !important;
  color: black !important;
}

.changePassword {
  cursor: pointer;
  font-weight: 700;
  margin-top: 10px;
}

.changePassword:hover {
  cursor: pointer;
  font-weight: 700;
  margin-top: 10px;
  color: #3ec4e5;
}

.deleteOrganization {
  cursor: pointer;
  color: #333541;
  font-weight: bold;
  margin-top: 10px;
}

.deleteOrganization:hover {
  color: #3ec4e5;
}

/* .side-panel-mission-buttons {
  border-radius: 4px;
  width: 60px;
  font-size: 10px;
} */

.clickable {
  cursor: pointer;
}

/* .help-icon {
  color: blue;
} */

/* .side-panel-mission-buttons:active {
    background-color: lightblue;
} */
